<template>
  <v-container fluid style="padding:15px 50px" class="my-4">
    <v-row>
      <v-btn
        v-for="(report, index) in reports"
        x-large
        :color="report.color"
        class="ma-2 white--text"
        :key="index"
        :href="`${reportUrl}&${report.param}`"
        target="_blank"
      >
        <v-icon left dark>
          {{ report.icon }}
        </v-icon>
        {{ report.name }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["campaignId", "projectId"],
  computed: {
    reportUrl() {
      return (
        this.$store.getters["auth/getApiHost"]["lnp_host"] +
        "/campaign/reports/index.htm?" +
        `jwt=${this.$store.state.auth.token}` +
        `&CAMPAIGN_OBJECT_ID=${this.campaignId}` +
        `&LNP_PROJECT_ID=${this.projectId}`
      );
    }
  },
  data() {
    return {
      reports: [
        {
          name: "Agent Dial",
          param: "tm_log=1&R_TYPE=DIAL_REPORT",
          icon: "mdi-phone-dial",
          color: "red"
        },
        {
          name: "Campaign List Usage",
          param: "list_usage_log=1&R_TYPE=DIAL_REPORT8",
          icon: "mdi-format-list-numbered",
          color: "lime"
        },
        {
          name: "Campaign Performance & Forecast",
          param: "R_TYPE=DIAL_REPORT5&forecast_log=1",
          icon: "mdi-chart-line",
          color: "cyan"
        },
        {
          name: "Attribute Breakdown",
          param: "R_TYPE=DIAL_REPORT7&attr_log=1",
          icon: "mdi-chart-bar",
          color: "green"
        },
        {
          name: "Scorecard",
          param: "dial_log=1&R_TYPE=DIAL_REPORT2",
          icon: "mdi-counter",
          color: "blue"
        },
        {
          name: "TM Email",
          param: "R_TYPE=DIAL_REPORT6",
          icon: "mdi-email-multiple",
          color: "pink"
        }
      ]
    };
  },
  methods: {}
};
</script>
