<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mt-4">
    <v-container fluid style="padding:15px 50px">
      <v-row>
        <template v-for="(item, index) in fields">
          <v-col :key="index" :cols="item.col ? item.col : 6">
            <v-autocomplete
              v-if="item.type == 'AUTO'"
              chips
              v-model="values[item.field]"
              :items="list.items"
              :loading="list.loading"
              :search-input.sync="list.search"
              hide-no-data
              hide-selected
              deletable-chips
              :label="item.label"
              placeholder="Start typing to search list"
              prepend-icon="mdi-database-search"
              multiple
            >
            </v-autocomplete>

            <InputControl
              v-else
              :key="index"
              :attrId="item.field"
              :label="item.label"
              :ref="`input_${item.field}`"
              :value.sync="values[item.field]"
              :attrType="item.type"
              :attrRef="lovs[item.field]"
              :multiValue="item.multiple"
              :disabled="item.disabled || disabled[item.field]"
              :rules="item.validation"
            />
          </v-col>
        </template>
        <v-col cols="6" v-if="projectId && values.MOD_DATE">
          <div class="caption">
            Modified on <b>{{ this.values.MOD_DATE }}</b> by <b>{{ this.values.USERNAME }}</b>
          </div>
          <div class="caption red--text" v-if="disabled.EXPIRED">
            Project expired, no push/pull action will be activated.
          </div>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="save" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import InputControl from "@/components/util/InputControl";

export default {
  components: {
    InputControl
  },
  props: ["campaignId", "projectId"],
  watch: {
    projectId: {
      immediate: true,
      handler() {
        this.loadForm();
      }
    },
    "list.search"(val) {
      this.searchExclusionList(val);
    }
  },
  data() {
    return {
      loading: false,
      values: {},
      lovs: {},
      disabled: {},
      modifiedFields: [],
      valid: true,
      list: {
        loading: false,
        search: null,
        items: [],
        source: undefined
      },
      exc_list_val_lov: [],
      fields: [
        { field: "PROJECT_ID", label: "Project ID", disabled: true },
        { field: "LINE_ORDER_NUMBER", label: "Line Order #" },
        { field: "PROJECT_NAME", label: "Project Name", validation: ["mandatory"] },
        { field: "CCMS_ID", label: "CCMS Project", type: "L", lov: [] },
        { field: "START_DATE", label: "Start Date", type: "D", validation: ["mandatory"] },
        { field: "END_DATE", label: "End Date", type: "D", validation: ["mandatory"] },
        {
          field: "PROJECT_STATUS",
          label: "Project Status",
          type: "L",
          validation: ["mandatory"]
        },
        {
          field: "CAMPAIGN_CTA",
          label: "Call To Action",
          type: "L",
          validation: ["mandatory"],
          lov: [],
          multiple: true
        },
        {
          field: "exclusion_lists",
          label: "Exclude Contacts By List Exclusion",
          type: "AUTO",
          col: "12"
        }
      ]
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.$axios({
        method: this.projectId == "+" ? "post" : "put",
        url: "/lnp/project/save_project",
        data: { ...this.values, projectId: this.projectId, campaignId: this.campaignId }
      })
        .then((res) => {
          if (!res.data || res.data.error) {
            this.$store.commit("sendAlert", {
              msg: res.data.error ?? "Nothing returned from api",
              color: "error"
            });
          } else {
            if (this.projectId == "+") {
              this.$router.push({ params: { id: res.data.projectId } });
            } else {
              this.fetchProject();
            }
            this.$emit("update", true);
            this.$store.commit("sendAlert", {
              msg: "Campaign saved",
              color: "success"
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchExclusionList(val) {
      if (val === null || val.length < 2) return;
      this.list.source && this.list.source.cancel("Operation canceled due to new request.");
      this.list.source = axios.CancelToken.source();

      this.list.loading = true;
      this.$axios
        .get("/lnp/project/search_project_exc_list", {
          params: {
            term: val,
            projectId: this.projectId
          },
          cancelToken: this.list.source.token
        })
        .then((res) => {
          if (res.data) {
            //console.log(this.values.exclusion_lists);
            this.list.items = [...this.exc_list_val_lov, ...res.data];
            //this.list.items = res.data;
          }
        })
        .finally(() => {
          this.list.loading = false;
        });
    },
    fetchProject() {
      this.loading = true;
      if (!this.projectId) this.values.PROJECT_ID = "-";
      this.$axios
        .get("/lnp/project/get_project", {
          params: {
            campaignId: this.campaignId,
            projectId: this.projectId
          }
        })
        .then((res) => {
          this.values = res.data.values;
          this.lovs = res.data.lovs;
          this.disabled = res.data.disabled;
          this.exc_list_val_lov = this.lovs.exclusion_lists;
          this.list.items = this.exc_list_val_lov;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadForm() {
      this.fetchProject();
    }
  }
};
</script>
