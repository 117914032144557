<template>
  <v-tabs v-if="projectId" v-model="tab">
    <v-tab>Info</v-tab>
    <template v-if="projectId != '+'">
      <v-tab>Resource Management</v-tab>
      <v-tab>Attributes</v-tab>
      <v-tab>Build List & Assignment</v-tab>
      <v-tab>Reports</v-tab>
    </template>

    <v-tab-item
      :transition="false"
      :reverse-transition="false"
      v-for="(item, index) in tabItem"
      :key="index"
    >
      <component
        :is="item"
        :campaignId="campaignId"
        :projectId="projectId"
        @update="onUpdate"
      ></component>
    </v-tab-item>
  </v-tabs>

  <v-card :loading="loading" v-else>
    <v-toolbar short flat>
      <v-toolbar-title> LNP Project </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>

      <v-spacer></v-spacer>
      <v-divider class="mx-4" inset vertical></v-divider>

      <v-btn
        v-for="action in actions"
        text
        color="primary"
        :key="action.name"
        @click="onActionClick(action.name)"
      >
        {{ action.text }}
      </v-btn>
    </v-toolbar>
    <v-divider />

    <v-data-table
      class="crud-table lnp-table"
      item-key="PROJECT_ID"
      v-model="selected"
      :headers="headers"
      :items="items"
      @click:row="goToProject"
      hide-default-footer
      show-select
      dense
    >
    </v-data-table>
  </v-card>
</template>

<script>
import ProjectInfo from "@/components/lnp/Project/Info";
import Resource from "@/components/lnp/Project/Resource";
import Attribute from "@/components/lnp/Project/Attribute/Listing";
import Assignment from "@/components/lnp/Project/Assignment/Listing";
import Report from "@/components/lnp/Project/Report";

export default {
  components: {
    ProjectInfo,
    Resource,
    Attribute,
    Assignment,
    Report
  },
  props: ["campaignId", "projectId"],
  watch: {
    projectId: {
      immediate: true,
      handler() {
        this.load();
      }
    }
  },
  data() {
    return {
      tab: 2,
      selected: [],
      items: [],
      headers: [
        {
          text: "Project Name",
          value: "PROJECT_NAME"
        },
        {
          text: "CCMS Project",
          value: "CCMS_PROJECT_NAME"
        },
        {
          text: "Start Date",
          value: "START_DATE",
          width: "100px"
        },
        {
          text: "End Date",
          value: "END_DATE",
          width: "100px"
        },
        {
          text: "Status",
          value: "PROJECT_STATUS",
          width: "100px"
        },
        {
          text: "Last Updated",
          value: "MOD_DATE",
          width: "170px"
        }
      ],
      actions: [
        { name: "deleteProject", text: "Delete" },
        { name: "addProject", text: "New Project" }
      ],
      tabItem: ["ProjectInfo", "Resource", "Attribute", "Assignment", "Report"],
      loading: false
    };
  },
  methods: {
    onUpdate() {
      this.$emit("update", true);
    },
    onActionClick(action) {
      switch (action) {
        case "addProject":
          this.$router.push({ params: { id: "+" } });
          break;
        case "deleteProject":
          this.$root
            .$confirm("Confirm Delete", "Delete selected project(s)?", {
              color: "red"
            })
            .then((confirm) => {
              if (confirm) {
                this.$axios
                  .delete("/lnp/project/delete_project", {
                    params: {
                      pids: this.selected.map((e) => {
                        return e["PROJECT_ID"];
                      })
                    }
                  })
                  .then(() => {
                    this.load();
                    this.onUpdate();
                    this.selected = [];
                  });
              }
            });

          break;
      }
    },
    goToProject(e) {
      this.$router.push({ params: { id: e.PROJECT_ID } });
    },
    load() {
      this.loading = true;
      this.$axios
        .get("/lnp/get_project_list", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          if (res.data) {
            this.items = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
