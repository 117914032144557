<template>
  <div style="display:content">
    <v-dialog
      scrollable
      :value="value"
      @keydown.esc="closeForm"
      @input="closeForm"
      max-width="1000px"
      persistent
    >
      <v-card :loading="loading">
        <v-toolbar short flat>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>List Assignment: {{ list.LIST_NAME }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="px-0">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="(header, i) in headers" :key="i">
                    <template v-if="header != 'HIDEALL'">{{ header }}</template>
                    <v-simple-checkbox
                      :ripple="false"
                      v-else
                      @input="toggleHide"
                      v-model="hideAll"
                    ></v-simple-checkbox>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(seg, i) in segments" :key="i">
                  <td :title="seg['tooltips']">{{ seg["SEGMENT_LABEL"] }}</td>
                  <td style="width: 200px">{{ seg["LEASTCOUNT"] }}</td>
                  <td style="width: 50px">{{ seg["TOTAL"] }}</td>
                  <td style="width: 300px">
                    <v-select
                      outlined
                      item-value="ADMIN_USER_ID"
                      item-text="USERNAME"
                      hide-details
                      dense
                      :items="agents"
                      v-model="values[seg['SEGMENT_NAME']]['userId']"
                    ></v-select>
                  </td>
                  <td style="width: 50px">
                    <v-checkbox
                      hide-details
                      class="pb-1 ma-0"
                      :ripple="false"
                      v-model="values[seg['SEGMENT_NAME']]['isHidden']"
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <template>
            <v-btn plain :disabled="saving" @click="closeForm">Cancel</v-btn>
            <v-btn plain color="primary" :disabled="loading || saving" @click="save()">Save</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["campaignId", "projectId", "list", "value"],
  data() {
    return {
      hideAll: false,
      loading: false,
      saving: false,
      values: {},
      agents: {},
      segments: {},
      headers: ["Segment Name", "#Contacted-Company", "Size", "Username", "HIDEALL"]
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    toggleHide(v) {
      for (let segment of Object.values(this.values)) {
        segment.isHidden = v;
      }
    },
    closeForm() {
      this.$emit("input", false);
    },
    save() {
      this.saving = true;
      this.$axios({
        method: "post",
        url: "/lnp/project/assignment/save_assignments",
        data: {
          listId: this.list.LIST_OBJECT_ID,
          projectId: this.projectId,
          data: this.values
        }
      })
        .then(() => {
          this.$store.commit("sendAlert", {
            msg: "List assignment updated",
            color: "success"
          });
        })
        .finally(() => {
          this.saving = this.building = false;
        });
    },
    fetch() {
      this.loading = true;
      this.$axios
        .get("/lnp/project/assignment/get_project_assignments", {
          params: {
            projectId: this.projectId,
            listId: this.list.LIST_OBJECT_ID
          }
        })
        .then((res) => {
          this.segments = res.data.segments;
          this.agents = res.data.agents;
          for (let segment of Object.values(this.segments)) {
            this.values[segment.SEGMENT_NAME] = {
              userId: segment.ADMIN_USER_ID,
              isHidden: segment.HIDE_LIST
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    load() {
      this.values = this.segments = {};
      this.hideAll = false;
      this.fetch();
    }
  }
};
</script>
