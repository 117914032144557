<template>
  <div style="display: contents">
    <CRUDForm
      ref="crudForm"
      :readOnly="true"
      @close="closeForm"
      :formSize="800"
      headerText="View attributes Clipboard"
      :loading="loading"
      :formActive="value"
    >
      <template v-slot:[`form.content`]="">
        <v-row class="py-5">
          <v-col cols="12">
            <v-data-table
              :options="{ itemsPerPage: -1 }"
              item-key="ATTR_ID"
              hide-default-footer
              :headers="headers"
              :items="items"
            >
              <template v-slot:[`item.DELETE`]="{ item }">
                <v-btn icon small @click="deleteRow(item.ATTR_ID)" :disabled="loading">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </CRUDForm>
  </div>
</template>

<script>
import CRUDForm from "@/components/util/CRUD/CRUDForm";

export default {
  components: {
    CRUDForm
  },
  props: ["campaignId", "projectId", "value"],
  data() {
    return {
      items: [],
      loading: false,
      headers: [
        {
          text: "Name",
          value: "NAME",
          width: "200px"
        },
        {
          text: "Source",
          value: "SOURCE"
        },
        {
          text: "Delete",
          value: "DELETE",
          sortable: false,
          width: "100px"
        }
      ]
    };
  },
  computed: {
    clipboard() {
      return this.$store.getters["clipboard/getClipboard"];
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    deleteRow(attrId) {
      this.$store.dispatch("clipboard/delete", attrId);
      this.load();
    },
    closeForm() {
      this.$emit("input", false);
    },
    load() {
      this.loading = true;
      this.$axios
        .get("/lnp/project/attribute/get_clipboard", {
          params: {
            attrs: this.clipboard
          }
        })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
