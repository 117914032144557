<template>
  <div style="display:content">
    <v-form ref="form" v-model="valid" lazy-validation class="mt-4">
      <v-container fluid style="padding:15px 50px">
        <v-row class="mb-2">
          <template v-for="(item, index) in fields">
            <v-col :key="index" cols="12">
              <v-autocomplete
                chips
                v-model="values[item.role]"
                :items="lovs[item.role]"
                :loading="item.loading"
                outlined
                hide-no-data
                hide-selected
                hide-details
                :label="item.label"
                placeholder="Start searching by typing 2 or more chars"
                multiple
                deletable-chips
                @update:search-input="search($event, item.role)"
              >
              </v-autocomplete>
            </v-col>
          </template>
        </v-row>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn text @click="outsourcer.dialog = true">Add Outsourcer</v-btn>
          <v-spacer />
          <v-btn color="primary" @click="save" :loading="loading">Save</v-btn>
        </v-card-actions>
      </v-container>
    </v-form>
    <v-dialog
      v-model="outsourcer.dialog"
      max-width="600px"
      @keydown.esc="outsourcer.dialog = false"
    >
      <v-card>
        <v-card-title> Add outsourcer </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    hide-details="auto"
                    dense
                    label="Username"
                    v-model="outsourcer.username"
                    :rules="[(value) => !!value || 'Required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    hide-details="auto"
                    dense
                    type="password"
                    label="Password"
                    v-model="outsourcer.password"
                    :rules="[(value) => !!value || 'Required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="saveOutsourcer" :loading="outsourcer.loading"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  props: ["campaignId", "projectId"],
  watch: {
    projectId: {
      immediate: true,
      handler() {
        this.loadForm();
      }
    }
  },
  data() {
    return {
      outsourcer: {
        loading: false,
        dialog: false,
        password: undefined,
        username: undefined
      },
      loading: false,
      originalVal: {},
      values: {},
      lov: [],
      lovs: {},
      source: {},
      searchLoading: {},
      valid: true,
      campaignCountry: false,
      fields: [
        { role: "1", label: "Campaign Managers" },
        { role: "2", label: "BDMs" },
        { role: "3", label: "QCers" },
        { role: "4", label: "Agents" }
      ]
    };
  },
  methods: {
    saveOutsourcer() {
      this.outsourcer.loading = true;
      this.$axios({
        method: "put",
        url: "/lnp/project/resource/save_resource",
        data: {
          projectId: this.projectId,
          campaignId: this.campaignId,
          campaignCountry: this.campaignCountry,
          userDetail: {
            PASSWORD: this.outsourcer.password,
            USERNAME: this.outsourcer.username
          }
        }
      })
        .then((res) => {
          this.outsourcer.dialog = false;
          this.$store.commit("sendAlert", {
            msg: "User has been created and assigned to project as agent",
            color: "success"
          });
          this.lovs[4].push(res.data);
          this.values[4].push(res.data.value);
          this.originalVal[4].push(res.data.value);
        })
        .finally(() => {
          this.outsourcer.loading = false;
        });
    },
    save() {
      this.loading = true;
      this.$axios({
        method: "put",
        url: "/lnp/project/save_resource",
        data: {
          data: this.values,
          originalData: this.originalVal,
          projectId: this.projectId,
          campaignId: this.campaignId,
          campaignCountry: this.campaignCountry
        }
      })
        .then((res) => {
          if (res.data) {
            this.$store.commit("sendAlert", {
              msg: "User assignments updated",
              color: "success"
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search(val, role) {
      if (val === null || val.length < 2) return;
      this.source[role] && this.source[role].cancel("Operation canceled due to new request.");
      this.source[role] = axios.CancelToken.source();
      this.searchLoading[role] = true;
      this.$axios
        .get("/lnp/project/resource/search_resource", {
          params: {
            name: val,
            projectId: this.projectId,
            role: role,
            campaignCountry: this.campaignCountry
          },
          cancelToken: this.source[role].token
        })
        .then((res) => {
          if (res.data) {
            let tmp = this.lovs;
            this.lovs = [];
            tmp[role] = [...(tmp[role] ?? []), ...res.data];
            this.lovs = tmp;
          }
        })
        .finally(() => {
          this.searchLoading[role] = false;
        });
    },
    fetch() {
      this.loading = true;
      this.$axios
        .get("/lnp/project/resource/get_resource", {
          params: {
            campaignId: this.campaignId,
            projectId: this.projectId
          }
        })
        .then((res) => {
          this.campaignCountry = res.data.campaign_country;
          this.lovs = { ...res.data.user };
          this.values = { ...res.data.values };
          this.originalVal = JSON.parse(JSON.stringify(res.data.values));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadForm() {
      this.fetch();
    }
  }
};
</script>
