<template>
  <div style="display: contents">
    <v-container fluid>
      <v-card :loading="loading">
        <v-toolbar short flat>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-btn outlined @click="onActionClick('new')" :loading="loading">
            <v-icon left>
              mdi-plus
            </v-icon>
            Build New List
          </v-btn>
        </v-toolbar>
        <v-divider />

        <v-data-table
          class="crud-table lnp-table"
          :options="{ itemsPerPage: -1 }"
          item-key="LIST_OBJECT_ID"
          v-model="selected"
          :headers="headers"
          :items="items"
          @click:row="onRowClick"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.ACTIONS`]="{ item }">
            <ActionButton
              v-for="(action, index) in rowActions"
              :key="index"
              :icon="action.icon"
              :tooltip="action.text"
              :iconBind="{ small: true }"
              @action-click="onActionClick(action.name, item)"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <EditForm
      :listId="activeListId"
      :campaignId="campaignId"
      :projectId="projectId"
      :lovs="formEdit.lovs"
      v-model="formEdit.active"
      @update="onUpdate"
    />
    <AssignForm
      :list="formAssign.list"
      :campaignId="campaignId"
      :projectId="projectId"
      :agents="formAssign.agents"
      v-model="formAssign.active"
    />
  </div>
</template>

<script>
import ActionButton from "@/components/util/ActionButton";
import EditForm from "@/components/lnp/Project/Assignment/EditForm";
import AssignForm from "@/components/lnp/Project/Assignment/AssignForm";

export default {
  components: {
    ActionButton,
    EditForm,
    AssignForm
  },
  props: ["campaignId", "projectId"],
  data() {
    return {
      activeListId: null,
      formEdit: {
        loading: false,
        active: false,
        lovs: {}
      },
      formAssign: {
        loading: false,
        active: false,
        list: {}
      },
      selected: [],
      loading: false,
      rowActions: [
        { name: "assign", text: "Assign", icon: "mdi-playlist-edit" },
        { name: "delete", text: "Delete", icon: "mdi-delete-forever" }
      ],
      items: [],
      headers: [
        {
          text: "List Name",
          value: "LIST_NAME"
        },
        {
          text: "Companies",
          value: "LIST_COUNT",
          width: "50px"
        },
        {
          text: "Contacts",
          value: "TOTAL_CONTACT",
          width: "50px"
        },
        {
          text: "Segment Count",
          value: "SEGMENT_COUNT",
          width: "50px"
        },
        {
          text: "Actions",
          value: "ACTIONS",
          width: "150px",
          align: "center"
        }
      ]
    };
  },
  watch: {
    projectId: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  computed: {
    // assetId() {
    //   return this.$route.params.id;
    // }
  },
  methods: {
    onUpdate(listId) {
      if (this.activeListId != listId) this.activeListId = listId;
      this.fetchList();
    },
    onActionClick(e, list = null) {
      switch (e) {
        case "new":
        case "edit":
          if (list) this.activeListId = list.LIST_OBJECT_ID;
          this.formEdit.active = true;
          break;
        case "assign":
          this.formAssign.list = list;
          this.formAssign.active = true;
          break;
        case "delete":
          this.delete(list.LIST_OBJECT_ID);
          break;
      }
    },
    delete(listId) {
      this.$root
        .$confirm("Delete", "Are you sure you want to delete this list?", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            this.$axios
              .delete("/lnp/project/assignment/delete_list", {
                params: {
                  projectId: this.projectId,
                  listId: listId
                }
              })
              .then((res) => {
                if (res.data.status) {
                  this.fetchList();
                  this.$store.commit("sendAlert", {
                    msg: "List deleted",
                    color: "success"
                  });
                } else {
                  this.$store.commit("sendAlert", {
                    msg: "Deletion failed: ".res.data.error,
                    color: "error"
                  });
                }
              });
          }
        });
    },
    fetchList() {
      this.selected = [];
      this.formEdit.loading = true;
      this.$axios
        .get("/lnp/project/assignment/list", {
          params: {
            projectId: this.projectId
          }
        })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.formEdit.loading = false;
        });
    },
    fetchLov() {
      this.$axios
        .get("/lnp/project/assignment/get_lovs", {
          params: {
            projectId: this.projectId,
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          this.formEdit.lovs = res.data;
        });
    },
    load() {
      this.loading = true;
      Promise.all([this.fetchList(), this.fetchLov()]).finally(() => {
        let self = this;
        // wait 1 sec for query UI to render
        setTimeout(() => {
          self.loading = false;
        }, 1000);
      });
    },
    onRowClick(e) {
      this.activeListId = e.LIST_OBJECT_ID;
      this.formEdit.active = true;
    }
  }
};
</script>
