var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"contents"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"short":"","flat":""}},[_vm._l((_vm.cbActions),function(action){return _c('ActionButton',{key:action.name,attrs:{"icon":action.icon,"tooltip":action.text,"disabled":(action.name == 'copy' && _vm.selected.length == 0) ||
              (action.name != 'copy' && _vm.clipboard.length == 0)},on:{"action-click":function($event){return _vm.onActionClick(action.name)}}})}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',_vm._l((_vm.moreActions),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.onActionClick(item.name)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),_c('v-spacer'),_vm._l((_vm.actions),function(action){return _c('ActionButton',{key:action.name,attrs:{"icon":action.icon,"tooltip":action.text,"disabled":action.name == 'delete' && _vm.selected.length == 0},on:{"action-click":function($event){return _vm.onActionClick(action.name)}}})}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.onActionClick('useLNDBAttr')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-database-arrow-down ")]),_vm._v(" Use LNDB Attribute ")],1)],2),_c('v-divider'),_c('v-data-table',{staticClass:"crud-table lnp-table",attrs:{"show-select":"","item-key":"ATTR_ID","headers":_vm.headers,"items":_vm.items,"options":{ itemsPerPage: -1 },"fixed-header":"","hide-default-footer":"","dense":""},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.ATTR_NAME",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:[item.class ? item.class : '', 'truncate'],attrs:{"title":item.ATTR_NAME}},[_vm._v(" "+_vm._s(item.ATTR_NAME)+" ")])]}},{key:"item.ATTR_LABEL",fn:function(ref){
              var item = ref.item;
return [(item.ATTR_LABEL)?_c('div',{staticClass:"truncate",attrs:{"title":item.ATTR_LABEL}},[_vm._v(" "+_vm._s(item.ATTR_LABEL)+" ")]):_vm._e()]}},{key:"item.MANDATORY",fn:function(ref){
              var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.MANDATORY ? '&#10004;' : '')}})]}},{key:"item.ADOBESYNC",fn:function(ref){
              var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.ADOBESYNC ? '&#10004;' : '')}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('AttrSetupForm',{attrs:{"campaignId":_vm.campaignId,"projectId":_vm.projectId,"attrId":_vm.setupForm.attrId},on:{"update":_vm.onUpdate},model:{value:(_vm.setupForm.active),callback:function ($$v) {_vm.$set(_vm.setupForm, "active", $$v)},expression:"setupForm.active"}}),_c('AttrReuseForm',{attrs:{"campaignId":_vm.campaignId,"projectId":_vm.projectId},on:{"update":function($event){return _vm.onUpdate($event, 'reuse')}},model:{value:(_vm.reuseForm),callback:function ($$v) {_vm.reuseForm=$$v},expression:"reuseForm"}}),_c('Clipboard',{attrs:{"campaignId":_vm.campaignId,"projectId":_vm.projectId},model:{value:(_vm.otherForm.clipboard),callback:function ($$v) {_vm.$set(_vm.otherForm, "clipboard", $$v)},expression:"otherForm.clipboard"}}),_c('Sorting',{attrs:{"campaignId":_vm.campaignId,"projectId":_vm.projectId,"attrList":_vm.items},on:{"update:attrList":function($event){_vm.items=$event},"update:attr-list":function($event){_vm.items=$event}},model:{value:(_vm.otherForm.sorting),callback:function ($$v) {_vm.$set(_vm.otherForm, "sorting", $$v)},expression:"otherForm.sorting"}}),_c('ProjectLeadID',{attrs:{"campaignId":_vm.campaignId,"projectId":_vm.projectId},on:{"update":_vm.fetchList},model:{value:(_vm.otherForm.leadIdentifier),callback:function ($$v) {_vm.$set(_vm.otherForm, "leadIdentifier", $$v)},expression:"otherForm.leadIdentifier"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }