<template>
  <div style="display: contents">
    <CRUDForm
      :persistent="false"
      ref="crudForm"
      @close="closeForm"
      :formSize="650"
      headerText="Project Lead Identifier"
      :loading="loading"
      :formActive="value"
      @save="save"
      :alwaysPostAll="true"
    >
      <template v-slot:[`form.content`]="">
        <v-row>
          <v-col cols="12">
            <v-select
              class="mt-2"
              :menu-props="{ maxWidth: '744px' }"
              v-model="values.SELECTED_ATTR_ID"
              :items="lov.CONTACT"
              label="Contact Attribute"
              dense
              outlined
              :rules="[(value) => !!value || 'Required']"
              hide-details
              @change="loadLeadRef"
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-card outlined :loading="leadRef.loading">
              <v-card-text v-if="values.SELECTED_ATTR_ID">
                <div class="d-flex mb-2" v-for="(item, i) in leadRef.items" :key="i">
                  <v-checkbox
                    v-model="leadRef.values.SELECTED_ATTR_REF_ID[item.refid]"
                    class="mt-1"
                    :label="item.name"
                    hide-details
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                  <div style="width: 250px">
                    <v-select
                      v-model="leadRef.values.SELECTED_CONTACT_OP[item.refid]"
                      :items="leadOption"
                      dense
                      outlined
                      hide-details
                      :rules="[(value) => !!value || 'Required']"
                    >
                    </v-select>
                  </div>
                </div>
              </v-card-text>
              <v-card-text v-else>
                Please select a Contact Attribute
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="values.COMPANY_SELECTED_ATTR_ID"
              :menu-props="{ maxWidth: '744px' }"
              :items="lov.COMPANY"
              label="Company Attribute"
              dense
              outlined
              :rules="[(value) => !!value || 'Required']"
              hide-details
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="values.QC_SELECTED_ATTR_ID"
              :menu-props="{ maxWidth: '744px' }"
              :items="lov.CONTACT"
              label="QC Attribute"
              dense
              outlined
              :rules="[(value) => !!value || 'Required']"
              hide-details
              width="200px"
            >
            </v-select>
          </v-col>
        </v-row>
      </template>
    </CRUDForm>
  </div>
</template>

<script>
import CRUDForm from "@/components/util/CRUD/CRUDForm";

export default {
  components: {
    CRUDForm
  },
  props: ["campaignId", "projectId", "value"],
  data() {
    return {
      loading: false,
      leadRef: {
        values: {
          SELECTED_ATTR_REF_ID: {},
          SELECTED_CONTACT_OP: {}
        },
        loading: false,
        items: []
      },
      lov: [],
      values: {},
      leadOption: [
        { text: "Contacted", value: 1 },
        { text: "Not Contacted", value: 2 }
      ]
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    save() {
      this.$axios
        .put("/lnp/project/attribute/save_lead_identifier", {
          campaignId: this.campaignId,
          projectId: this.projectId,
          data: { ...this.values, ...this.leadRef.values }
        })
        .then(() => {
          this.$store.commit("sendAlert", {
            msg: "Lead Identifier updated successfully",
            color: "success"
          });
          this.$emit("update", true);
        })
        .finally(() => {
          this.$refs.crudForm.saved();
        });
    },
    sort() {},
    closeForm() {
      this.$emit("input", false);
    },
    loadLeadRef() {
      this.leadRef.loading = true;
      this.$axios
        .get("/lnp/project/attribute/get_lead_attr_ref", {
          params: {
            campaignId: this.campaignId,
            projectId: this.projectId,
            attrId: this.values.SELECTED_ATTR_ID
          }
        })
        .then((res) => {
          this.leadRef.items = res.data.items;
          this.leadRef.values = res.data.values;
        })
        .finally(() => {
          this.leadRef.loading = false;
        });
    },
    load() {
      this.loading = true;
      this.$axios
        .get("/lnp/project/attribute/get_lead_attr", {
          params: {
            campaignId: this.campaignId,
            projectId: this.projectId
          }
        })
        .then((res) => {
          this.lov = res.data.lov;
          this.values = res.data.values;
          this.loadLeadRef();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
