<template>
  <div style="display: contents">
    <CRUDForm
      :persistent="false"
      ref="crudForm"
      @close="closeForm"
      :formSize="650"
      headerText="Re-order Attributes"
      :loading="loading"
      :formActive="value"
      @save="save"
      :alwaysPostAll="true"
    >
      <template v-slot:[`form.content`]="">
        <v-row>
          <v-col cols="12">
            <ol>
              <draggable v-model="items" @change="sort">
                <li v-for="(item, index) in items" :key="index">
                  {{ item.ATTR_NAME }}
                </li>
              </draggable>
            </ol>
          </v-col>
        </v-row>
      </template>
    </CRUDForm>
  </div>
</template>

<script>
import CRUDForm from "@/components/util/CRUD/CRUDForm";
import draggable from "vuedraggable";

export default {
  components: {
    CRUDForm,
    draggable
  },
  props: ["campaignId", "projectId", "attrList", "value"],
  data() {
    return {
      loading: false,
      items: []
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    },
    attrList: {
      handler(v) {
        if (v) {
          this.load();
        }
      }
    }
  },
  methods: {
    save() {
      let seqs = this.items.map((e) => {
        return e.ATTR_ID;
      });
      this.$axios
        .put("/lnp/project/attribute/reorder", {
          projectId: this.projectId,
          seqs: seqs
        })
        .then(() => {
          this.$emit("update:attrList", this.items);
        })
        .finally(() => {
          this.$refs.crudForm.saved();
        });
    },
    sort() {},
    closeForm() {
      this.$emit("input", false);
    },
    load() {
      this.items = this.attrList;
    }
  }
};
</script>

<style scoped>
li {
  cursor: move;
  padding: 4px 0;
}
li:hover {
  background: #f2f2f2;
}
</style>
