<template>
  <div style="display: contents">
    <v-container fluid>
      <v-card :loading="loading">
        <v-toolbar short flat>
          <ActionButton
            v-for="action in cbActions"
            :key="action.name"
            :icon="action.icon"
            :tooltip="action.text"
            :disabled="
              (action.name == 'copy' && selected.length == 0) ||
                (action.name != 'copy' && clipboard.length == 0)
            "
            @action-click="onActionClick(action.name)"
          />
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-menu bottom right offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list flat>
              <v-list-item-group>
                <v-list-item
                  @click="onActionClick(item.name)"
                  v-for="(item, i) in moreActions"
                  :key="i"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>
          <ActionButton
            v-for="action in actions"
            :key="action.name"
            :icon="action.icon"
            :tooltip="action.text"
            :disabled="action.name == 'delete' && selected.length == 0"
            @action-click="onActionClick(action.name)"
          />
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn outlined @click="onActionClick('useLNDBAttr')">
            <v-icon left>
              mdi-database-arrow-down
            </v-icon>
            Use LNDB Attribute
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-data-table
          class="crud-table lnp-table"
          show-select
          item-key="ATTR_ID"
          v-model="selected"
          :headers="headers"
          :items="items"
          :options="{ itemsPerPage: -1 }"
          @click:row="onRowClick"
          fixed-header
          hide-default-footer
          dense
        >
          <template v-slot:[`item.ATTR_NAME`]="{ item }">
            <div :title="item.ATTR_NAME" :class="[item.class ? item.class : '', 'truncate']">
              {{ item.ATTR_NAME }}
            </div>
          </template>
          <template v-slot:[`item.ATTR_LABEL`]="{ item }">
            <div :title="item.ATTR_LABEL" class="truncate" v-if="item.ATTR_LABEL">
              {{ item.ATTR_LABEL }}
            </div>
          </template>
          <template v-slot:[`item.MANDATORY`]="{ item }">
            <div v-html="item.MANDATORY ? '&#10004;' : ''" />
          </template>
          <template v-slot:[`item.ADOBESYNC`]="{ item }">
            <div v-html="item.ADOBESYNC ? '&#10004;' : ''" />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <AttrSetupForm
      :campaignId="campaignId"
      :projectId="projectId"
      v-model="setupForm.active"
      :attrId="setupForm.attrId"
      @update="onUpdate"
    />
    <AttrReuseForm
      :campaignId="campaignId"
      :projectId="projectId"
      v-model="reuseForm"
      @update="onUpdate($event, 'reuse')"
    />
    <Clipboard :campaignId="campaignId" :projectId="projectId" v-model="otherForm.clipboard" />
    <Sorting
      :campaignId="campaignId"
      :projectId="projectId"
      :attrList.sync="items"
      v-model="otherForm.sorting"
    />
    <ProjectLeadID
      :campaignId="campaignId"
      :projectId="projectId"
      v-model="otherForm.leadIdentifier"
      @update="fetchList"
    />
  </div>
</template>

<script>
import ActionButton from "@/components/util/ActionButton";
import AttrReuseForm from "@/components/lnp/Form/AttrReuseForm";
import AttrSetupForm from "@/components/lnp/Form/AttrSetupForm";
import Clipboard from "@/components/lnp/Project/Attribute/Clipboard";
import Sorting from "@/components/lnp/Project/Attribute/Sorting";
import ProjectLeadID from "@/components/lnp/Project/Attribute/ProjectLeadID";

export default {
  components: {
    ActionButton,
    AttrSetupForm,
    AttrReuseForm,
    Clipboard,
    Sorting,
    ProjectLeadID
  },
  props: ["campaignId", "projectId"],
  computed: {
    clipboard() {
      return this.$store.getters["clipboard/getClipboard"];
    }
  },
  data() {
    return {
      otherForm: {
        clipboard: false,
        sorting: false,
        leadIdentifier: false
      },
      setupForm: {
        active: false,
        attrId: undefined
      },
      reuseForm: false,
      selected: [],
      loading: false,
      searchVal: "",
      actions: [
        { name: "delete", text: "Delete", icon: "mdi-delete-forever" },
        { name: "new", text: "Add Attribute", icon: "mdi-plus" }
      ],
      cbActions: [
        { name: "copy", text: "Copy Selected Attribute", icon: "mdi-content-copy" },
        { name: "paste", text: "Paste Copied Attribute", icon: "mdi-content-paste" },
        { name: "clipboard", text: "View clipboard", icon: "mdi-clipboard-search" }
      ],
      moreActions: [
        { name: "reorder", text: "Re-order Attributes" },
        // { name: "mapping", text: "TM DB Mapping" },
        // { name: "branching", text: "Attributes Branching" },
        { name: "leadsId", text: "Lead Identifier" }
      ],
      items: [],
      headers: [
        {
          text: "ID",
          value: "ATTR_ID",
          width: "150px"
        },
        {
          text: "Attribute Name",
          value: "ATTR_NAME"
        },
        {
          text: "Attribute Label",
          value: "ATTR_LABEL"
        },
        {
          text: "Type",
          value: "ATTR_TYPE",
          width: "50px"
        },
        {
          text: "Value",
          value: "OBJECT_TYPE",
          width: "50px"
        },
        {
          text: "Last Updated",
          value: "MOD_DATE",
          width: "100px"
        },
        {
          text: "Mandatory",
          value: "MANDATORY",
          align: "center",
          width: "50px"
        },
        {
          text: "Sync to Adobe",
          value: "ADOBESYNC",
          align: "center",
          width: "50px"
        }
      ],
      fields: []
    };
  },
  watch: {
    projectId: {
      immediate: true,
      handler(v) {
        if (v) this.fetchList();
      }
    }
  },
  methods: {
    onUpdate(attrId, type = "setup") {
      if (type == "setup") {
        if (this.setupForm.attrId != attrId) this.setupForm.attrId = attrId;
      } else {
        // close reuse form after saved
        this.reuseForm = false;
      }
      this.fetchList();
    },
    onActionClick(e) {
      switch (e) {
        case "new":
          this.setupForm.active = true;
          this.setupForm.attrId = undefined;
          break;
        case "useLNDBAttr":
          this.reuseForm = true;
          break;
        case "delete":
          this.delete();
          break;
        case "copy":
          this.$store.dispatch("clipboard/copy", this.selected);
          break;
        case "paste":
          this.paste();
          break;
        case "clipboard":
          this.otherForm.clipboard = true;
          break;
        case "reorder":
          this.otherForm.sorting = true;
          break;
        case "leadsId":
          this.otherForm.leadIdentifier = true;
          break;
      }
    },
    paste() {
      this.loading = true;
      this.$axios
        .post("/lnp/project/attribute/paste", {
          campaignId: this.campaignId,
          projectId: this.projectId,
          copiedAttrs: this.clipboard
        })
        .then(() => {
          this.$store.commit("sendAlert", {
            msg: "Attributes copied successfully",
            color: "success"
          });
          this.fetchList();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    delete() {
      let idsToDelete = this.selected.map((e) => {
        return e["ATTR_ID"];
      });

      this.$root.$confirm("Delete", "Are you sure?", { color: "red" }).then((confirm) => {
        if (confirm) {
          this.$axios
            .delete("/lnp/db/design/deleteAttr", {
              params: {
                ids: idsToDelete,
                campaignId: this.campaignId
              }
            })
            .then(() => {
              this.fetchList();
              this.$store.commit("sendAlert", {
                msg: "Attribute deleted",
                color: "success"
              });
            });
        }
      });
    },
    fetchList() {
      this.selected = [];
      this.loading = true;
      this.$axios
        .get("/lnp/project/attribute/list", {
          params: {
            campaignId: this.campaignId,
            projectId: this.projectId
          }
        })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClick(e) {
      this.setupForm.active = true;
      this.setupForm.attrId = e.ATTR_ID.split("#")[0];
    }
  }
};
</script>

<style scoped>
.truncate {
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leads {
  color: #8b008b;
  font-weight: bold;
}

.mapped {
  color: #0000ff;
  font-weight: bold;
}
</style>
