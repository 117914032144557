<template>
  <div style="display:content">
    <v-dialog
      eager
      scrollable
      :value="value"
      @keydown.esc="closeForm"
      @input="closeForm"
      max-width="1000px"
      persistent
    >
      <v-card :loading="loading">
        <v-toolbar short flat>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            listId ? "Viewing List: " + listId : "Building new list"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="contentContainer">
          <v-form ref="form" v-model="valid">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="values.LIST_NAME"
                    hide-details="auto"
                    dense
                    label="List Name*"
                    :rules="[(v) => !!v || 'Required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="listId && !clicked.count">
                  <v-card class="pa-2 pb-0" outlined>
                    <v-label>
                      List Size
                    </v-label>
                    <v-card-text class="Caption">
                      <pre>
Total: {{ values.LIST_COUNT }} / Tagged: {{ values.LIST_COUNT }} / Segment: {{
                          values.SEGMENT_COUNT
                        }}</pre
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- <v-col cols="12" v-if="values.LIST_SIZE">
                  <v-text-field
                    v-model="values.LIST_SIZE"
                    hide-details
                    dense
                    label="List Size"
                    disabled
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12">
                  <v-card class="pa-2" outlined>
                    <v-label>
                      Query*
                    </v-label>
                    <v-card-text>
                      <Query ref="query" :readOnly="readOnly" />
                    </v-card-text>
                  </v-card>
                </v-col>

                <template v-if="clicked.count || listId">
                  <v-col cols="12" v-if="clicked.count">
                    <v-card outlined>
                      <v-card-text>
                        <template v-if="count.company != null">
                          <pre class="red--text">
Company: {{ count.company }} / Contact: {{ count.contact }}</pre
                          >
                          <span v-if="count.company == 0" class="font-italic caption"
                            >List cannot be built without any company.</span
                          >
                        </template>
                        <template v-else>
                          <p class="caption">Getting count ...</p>
                          <v-progress-linear indeterminate color="yellow darken-2" />
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="4" v-for="n in 3" :key="n">
                    <v-select
                      v-model="values.SEGMENT_CRITERIA[n]"
                      hide-details
                      dense
                      :items="lovs.segment"
                      :label="'Segment Criteria ' + n"
                    ></v-select>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      outlined
                      v-model="values.SEGMENT_SIZE"
                      type="number"
                      hide-details="auto"
                      dense
                      label="Segment Size*"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      outlined
                      v-model="values.LIMIT_COUNT"
                      type="number"
                      hide-details="auto"
                      dense
                      label="Limit List Size"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      style="margin-top: 8px;;padding-top: 0px;"
                      v-model="values.LEASTCONTACTED"
                      hide-details
                      label="Segment by Least Contacted"
                    />
                  </v-col>
                  <v-col cols="12" v-if="clicked.preview">
                    <v-card outlined>
                      <v-simple-table dense v-if="segmentPreview.count > 0">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th width="80px" class="text-left">Seq</th>
                              <th class="text-left">Segment Name</th>
                              <th v-if="isLeastContacted" class="text-left">
                                #Contacted - Company
                              </th>
                              <th class="text-left">
                                Size
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row, i) in segmentPreview.details" :key="i">
                              <td>{{ i + 1 }}</td>
                              <td>{{ row.segment }}</td>
                              <td v-if="isLeastContacted">{{ row.least }}</td>
                              <td>{{ row.size }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-card-text v-else>
                        <p class="caption">Generating segment ...</p>
                        <v-progress-linear indeterminate color="yellow darken-2" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <template v-if="!readOnly">
            <v-btn plain @click="getCount()" :disabled="saving || !valid">Get Count</v-btn>
            <v-btn
              plain
              :disabled="loading || saving || !valid || !count.company"
              @click="preview()"
              >Preview Segment</v-btn
            >
            <v-btn
              plain
              color="primary"
              :disabled="loading || !valid || saving || !count.company"
              :loading="building"
              @click="buildList()"
              >Build</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Query from "@/components/database/Query";

export default {
  components: {
    Query
  },
  props: ["campaignId", "projectId", "listId", "lovs", "value"],
  data() {
    return {
      clicked: {},
      count: {},
      segmentPreview: {
        count: 0,
        segment: []
      },
      valid: false,
      loading: false,
      saving: false,
      building: false,
      loadingQuery: false,
      values: {},
      attrs: null
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  computed: {
    readOnly() {
      return !!this.listId;
    }
  },
  methods: {
    buildList() {
      this.saving = this.building = true;
      let query = this.$refs.query.getFilters();
      this.$axios({
        method: "post",
        url: "/lnp/project/assignment/build_list",
        data: {
          campaignId: this.campaignId,
          projectId: this.projectId,
          data: this.values,
          query: query
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.scrollToBottom();
            this.sendNotification("List building completed", true);
            this.$store.commit("sendAlert", {
              msg: "List building completed",
              color: "success"
            });
            this.$emit("update", res.data.listId);
          } else {
            this.$store.commit("sendAlert", {
              msg: "List building failed",
              color: "error"
            });
          }
        })
        .finally(() => {
          this.saving = this.building = false;
        });
    },
    preview() {
      this.clicked.preview = true;
      this.segmentPreview = {};
      this.saving = true;
      //this.scrollToBottom();
      let query = this.$refs.query.getFilters();
      this.$axios({
        method: "post",
        url: "/lnp/project/assignment/get_segment_preview",
        data: {
          campaignId: this.campaignId,
          projectId: this.projectId,
          data: this.values,
          query: query
        }
      })
        .then((res) => {
          this.isLeastContacted = this.values.LEASTCONTACTED;
          this.segmentPreview = res.data;
          this.scrollToBottom();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getCount() {
      this.clicked.count = true;
      this.count = {};
      this.saving = true;
      let query = this.$refs.query.getFilters();
      //this.scrollToBottom();
      this.$axios({
        method: "post",
        url: "/lnp/project/assignment/get_query_count",
        data: {
          campaignId: this.campaignId,
          projectId: this.projectId,
          query: query
        }
      })
        .then((res) => {
          this.sendNotification("Query Count finished", true);
          this.count = res.data.count;
          this.scrollToBottom();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    scrollToBottom() {
      //scroll to bottom
      this.$nextTick(() => {
        document.getElementsByClassName("contentContainer")[0].scrollTop = 9999;
      });
    },
    closeForm() {
      this.$emit("input", false);
    },
    fetch() {
      this.loading = true;
      this.$axios
        .get("/lnp/project/assignment/get_list", {
          params: {
            campaignId: this.campaignId,
            projectId: this.projectId,
            listId: this.listId
          }
        })
        .then((res) => {
          this.values = res.data.list;
          this.$refs.query.init(res.data.query, this.lovs.attrs);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    load() {
      this.isLeastContacted = false;
      this.count = this.segmentPreview = {};
      this.clicked = {
        count: false,
        preview: false,
        build: false
      };
      this.values = {
        SEGMENT_CRITERIA: { 1: null, 2: null, 3: null }
      };
      if (this.listId) {
        this.fetch();
      } else {
        this.initForm();
      }
    },
    initForm() {
      this.$nextTick(() => {
        this.$refs.query.init(this.lovs.defaultQuery, this.lovs.attrs);
      });
    }
  }
};
</script>
